<template>
  <div class="start-menu">
    <section class="hero">
      <div class="hero__inner">
        <logo color="white" />
        <h1>
          Gestiona el FEEDBACK<br />
          que tu organización necesita
        </h1>
      </div>
    </section>

    <section class="flex">
      <div class="about">
        <h2>ELHA</h2>
        <p>
          ELHA es una plataforma que facilita el proceso de evaluación que tu
          organización necesita.
        </p>
      </div>

      <ul class="benefits">
        <li>
          <div></div>
          <p>
            Puedes acceder a la plataforma desde cualquier dispositivo móvil
            (computador, celular, tablet, ipad) y desde cualquier lugar del
            mundo.
          </p>
        </li>
        <li>
          <div></div>
          <p>
            Puedes visualizar los resultados que deseas en cualquier momento.
            Nuestra flexibilidad en el flujo de datos, permitirá tomar las
            acciones que necesita tu organización.
          </p>
        </li>
        <li>
          <div></div>
          <p>
            Sabemos que la información es un valor importante, por eso te
            aseguramos una plataforma segura, confiable y con absoluta
            confidencialidad garantizando la seguridad tu información.
          </p>
        </li>
      </ul>
    </section>

    <section class="video-section">
      <h2>ELHA, construyendo un mundo mejor</h2>
    </section>

    <section class="contact-section">
      <h2>Contáctanos</h2>
      <p>
        Si tienes dudas o necesitas apoyo para realizar la encuesta de tu
        organización, puedes comunicarte con nosotros que un asesor podrá
        ayudarte en todo lo que necesitas.
      </p>
      <p>Teléfono: +51984126441</p>
      <p>Correo: contacto@elhadigital.com</p>
    </section>

    <section class="ready-section">
      <h2>¡Empezamos!</h2>
    </section>

    <section class="social-section">
      <a
        class="fb-logo"
        href="https://www.facebook.com/Elha-Soluciones-Digitales-En-Recursos-Humanos-246589110575771/"
        target="_blank"
        rel="noreferrer nooponer"
      >
        <img
          class="brand-logo"
          src="../assets/facebook-logo.png"
          alt="Facebook logo"
        />
      </a>
      <a
        href="https://www.instagram.com/elhadigital/"
        target="_blank"
        rel="noreferrer nooponer"
      >
        <img
          class="brand-logo"
          src="../assets/instagram-logo.png"
          alt="Instagram logo"
        />
      </a>
      <a
        href="https://www.linkedin.com/company/elhadigital/"
        target="_blank"
        rel="noreferrer nooponer"
      >
        <img
          class="brand-logo"
          src="../assets/linkedin-logo.png"
          alt="LinkedIn logo"
        />
      </a>
    </section>
    <a
      href="https://api.whatsapp.com/send?phone=51984126441&text=%C2%A1Hola!,%20Quiero%20conocer%20m%C3%A1s%20sobre%20la%20plataforma%20online%20de%20clima%20laboral."
      target="_blank"
      rel="noopener noreferrer"
      class="whatsapp-btn"
    >
      <span>WhatsApp</span>
      <img src="@/assets/WhatsApp_Logo.png" alt="WhatsApp logo" />
    </a>

    <div class="copyright">
      <p>
        &copy; 2021, ELHA SOLUCIONES DIGITALES EN RECURSOS HUMANOS. Todos los
        derechos reservados.
      </p>
    </div>
  </div>
</template>

<script>
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/Logo.vue";

export default {
  name: "StartMenu",

  components: { Logo },

  data: () => ({
    fb: faFacebookF,
    ig: faInstagram,
    li: faLinkedinIn
  })
};
</script>

<style scoped>
.start-menu {
  padding: 0 !important;
  grid-gap: 0 !important;
}

.hero {
  flex-shrink: 0;
  height: 500px;
  margin: var(--lengthMd3);
  background-image: url("../assets/home.jpg");
  background-position: center;
  background-size: cover;
}

.hero__inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: var(--lengthLg2);
  backdrop-filter: brightness(0.6);
}

.hero h1 {
  flex-basis: 70%;
  align-self: center;
  color: white;
  font-size: 3rem;
  text-align: end;
}

.hero h1 span {
  color: var(--mainColor1);
}

.hero .logo-container {
  align-self: flex-end;
}

.flex {
  align-items: stretch;
}

.about {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--lengthMd3);
}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: var(--lengthMd3);
}

.about p {
  max-width: 50ch;
  font-size: var(--lengthMd1);
}

.benefits {
  flex-basis: 50%;
  margin: 0;
  padding: var(--lengthLg2);
  background-color: var(--grayColor1);
  list-style: none;
}

.benefits li {
  display: flex;
  align-items: center;
}

.benefits li + li {
  margin-top: var(--lengthMd3);
}

.benefits li div {
  width: var(--lengthMd2);
  height: var(--lengthMd2);
  flex: 0 0 auto;
  margin-right: var(--lengthMd3);
  background-color: var(--mainColor1);
  border-radius: 50%;
}

p {
  font-weight: var(--medium);
  color: var(--fontColor1);
}

h2 {
  font-size: 2.25rem;
}

.video-section {
  margin: var(--lengthLg2) auto;
}

.video-section h2 {
  margin-bottom: var(--lengthLg2);
  color: var(--mainColor1);
  text-align: center;
}

.video-box {
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.video-box p {
  padding: var(--lengthMd1);
  background-color: var(--grayColor1);
}

.video {
  flex-grow: 1;
  background-color: black;
}

.contact-section {
  padding: var(--lengthLg2);
  text-align: center;
  background-color: var(--grayColor1);
}

.contact-section p {
  max-width: 50ch;
  margin: var(--lengthMd2) auto;
}

.ready-section {
  padding: 8em 0;
  text-align: center;
  text-transform: uppercase;
}

.social-section {
  padding: 6em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: var(--lengthMd3);
  gap: var(--lengthMd3);
}

.whatsapp-btn {
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: flex;
  align-items: center;
  padding-left: var(--lengthMd1);
  color: white;
  background-color: #25d366;
  border-radius: var(--lengthSm1);
}

.whatsapp-btn img {
  max-width: var(--lengthLg3);
  border-radius: var(--lengthSm1);
}

.brand-logo {
  max-width: 32px;
}

.copyright {
  border-top: 1px rgba(128, 128, 128, 0.349) solid;
  padding: 2.7em 0;
  width: 88%;
  margin: 0 auto;
}

.copyright p {
  color: rgb(131, 128, 128);
  font-weight: 300;
}

.video {
  object-fit: cover;
  overflow: hidden;
}
</style>
